<template>
    <Modal :modal-title="$t('buttons.send_mail')"
           :dialog="dialog"
           max-width="600px"
           data-test="modal"
           @close="close"
           :closeIcon="false"
           @visibility="visibility($event)">
        <template slot="content">
            <ValidationObserver ref="obs">
                <MultiText :label="$t('labels.notification_email')"
                           name="recipients"
                           :rules="{required: true, email: true}"
                           :clear="clearMultipleText"
                           v-model="recipients"></MultiText>
                <TextField :label="$t('labels.title')"
                           name="title"
                           v-model="title"></TextField>
                <TextareaField :label="$t('labels.content')"
                           name="message"
                           v-model="message"></TextareaField>
            </ValidationObserver>
            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.send') }}</v-btn>
            </v-layout>
            <InformationDialog :dialog="informationVisibility"
                               @close="informationVisibility = false"
                               @visibility="informationVisibility = $event"
                               :text="$t('messages.email_sent')"></InformationDialog>
        </template>
    </Modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import Modal from '@/components/templates/Modal';
import TextField from '@/components/widgets/forms/TextField';
import MultiText from '@/components/widgets/forms/MultiText';
import TextareaField from '@/components/widgets/forms/TextareaField';
import ValidationError from '@/components/widgets/ValidationError';
import InformationDialog from '@/components/widgets/dialogs/InformationDialog';

export default {
    name: 'SendMail',
    components: { Modal, TextField, MultiText, TextareaField, ValidationObserver, ValidationError, InformationDialog },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        callback: {
            type: Function,
            required: true
        },
        defaultTitle: {
            type: String,
            'default': ''
        }
    },
    data: () => ({
        clearMultipleText: false,
        loader: false,
        showError: false,
        informationVisibility: false
    }),
    computed: {
        ...mapFields('sendMail', [
            'mailData.recipients',
            'mailData.title',
            'mailData.message'
        ])
    },
    watch: {
        dialog: {
            handler (val) {
                if (val) {
                    this.title = this.defaultTitle;
                    this.clearMultipleText = false;
                }
            },
            immediate: true
        },
        informationVisibility: {
            handler (val) {
                if (!val) {
                    this.close();
                }
            }
        }
    },
    methods: {
        close () {
            this.$emit('close');
            this.clearMultipleText = true;
            this.$refs.obs.reset();
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        save () {
            this.loader = true;

            this.$refs.obs.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.callback()
                            .then(() => {
                                this.informationVisibility = true;
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        }
    }
};
</script>

<style scoped>

</style>
