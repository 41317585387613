<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.global_violations_report') }}</h1>
        <FiltersBar :with-search="false"
                    @reset="setDatesFilters"
                    ref="filtersBar"></FiltersBar>
        <GlobalViolationsTable :loader="loading"
                               :xls-loader="xlsLoader"
                               :pdf-loader="pdfLoader"
                               :start-date="utcStartDate"
                               :end-date="utcEndDate"
                               :filters="mailParams"
                               @get-file="getFile($event, filesParams)"></GlobalViolationsTable>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import { PARSE_DATE, GET_BEGINNING_OF_CURRENT_MONTH, CONVERT_TO_UTC } from '@/helpers/dates';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import GlobalViolationsTable from '@/components/reports/GlobalViolationsTable';
import filtering from '@/mixins/filtering';
import debouncedTemplates from '@/mixins/debounced/debouncedTemplates';
import reportsFiles from '@/mixins/reportsFiles';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';

export default {
    name: 'GlobalViolationsReport',
    mixins: [filtering, debouncedTemplates, reportsFiles],
    components: { FiltersBar, GlobalViolationsTable },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        loading: false
    }),
    computed: {
        ...mapState('templates', [
            'totalTemplatesAmount'
        ]),
        ...mapGetters('templates', [
            'filterableTemplates'
        ]),
        filtersConfig () {
            return [
                new DatePickerFilter({
                    model: FILTERS.START_DATE,
                    label: this.$t('labels.date_from')
                }),
                new DatePickerFilter({
                    model: FILTERS.END_DATE,
                    label: this.$t('labels.date_to')
                }),
                new AutocompleteFilter({
                    model: FILTERS.TEMPLATE_ID,
                    loading: true,
                    multiple: true,
                    label: this.$t('labels.templates'),
                    search: FILTERS_SEARCHING.TEMPLATE,
                    lazy: this.getTemplatesLazyLoading,
                    searchCallback: this.debounceGetTemplates,
                    items: {
                        obj: 'getters',
                        module: 'templates',
                        prop: 'filterableTemplates'
                    }
                })
            ];
        },
        ...mapState('filters', [
            'filters'
        ]),
        startDate () {
            return this.filters[FILTERS.START_DATE] || GET_BEGINNING_OF_CURRENT_MONTH();
        },
        endDate () {
            return this.filters[FILTERS.END_DATE] || PARSE_DATE(new Date());
        },
        utcStartDate () {
            return CONVERT_TO_UTC(`${this.startDate} 00:00:00`);
        },
        utcEndDate () {
            return CONVERT_TO_UTC(`${this.endDate} 23:59:59`);
        },
        filesParams () {
            return {
                kind: 'irregularities',
                ...this.generateFilters()
            };
        },
        mailParams () {
            const params = {
                start_time: CONVERT_TO_UTC(`${this.startDate} 00:00:00`),
                end_time: CONVERT_TO_UTC(`${this.endDate} 23:59:59`),
                filter: {}
            };

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f !== FILTERS.START_DATE && f !== FILTERS.END_DATE) {
                        params.filter[f] = this.filters[f].join(',');
                    }
                }
            });

            return params;
        }
    },
    methods: {
        fetchGlobalViolations () {
            this.loading = true;

            const params = {
                ...this.generateFilters()
            };

            this.$store.dispatch('reports/getGlobalViolations', params)
                .finally(() => {
                    this.loading = false;
                });
        },
        generateFilters () {
            const params = { };

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f === FILTERS.START_DATE) {
                        params.start_time = CONVERT_TO_UTC(`${this.startDate} 00:00:00`);
                    } else if (f === FILTERS.END_DATE) {
                        params.end_time = CONVERT_TO_UTC(`${this.endDate} 23:59:59`);
                    } else {
                        params[`filter[${f}]`] = this.filters[f].join(',');
                    }
                }
            });

            return params;
        },
        setDatesFilters () {
            this.$refs.filtersBar.setSpecifyFilter(FILTERS.START_DATE, this.startDate);
            this.$refs.filtersBar.setSpecifyFilter(FILTERS.END_DATE, this.endDate);
        }
    },
    mounted () {
        this.setFiltersWatcher(this.fetchGlobalViolations);
        this.setDatesFilters();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.CUSTOM_REPORTS)) {
                vm.fetchGlobalViolations();
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('templates/getTemplates', {
                    'page[number]': '1',
                    'filter[archive]': false,
                    'filter[current]': true
                }).then(() => {
                    vm.filtersConfig.find(el => el.model === FILTERS.TEMPLATE_ID).loading = false;
                    vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                });
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }
};
</script>

<style scoped>

</style>
