<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  class="mb-8">
            <GetFile :loader="pdfLoader"
                     class="mr-3"
                     @get-file="emitGetFile('pdf')">
            </GetFile>
            <GetFile :title="$t('buttons.export_xls')"
                     :loader="xlsLoader"
                     @get-file="emitGetFile('xls')">
                <template slot="icon">
                    <v-icon class="mr-1"
                            size="20">{{ ICONS.EXPORT }}</v-icon>
                </template>
            </GetFile>
            <v-btn color="secondary"
                   small
                   @click="showSendMail"
                   class="ml-3">{{ $t('buttons.send_mail') }}</v-btn>
            <SendMail :dialog="sendMailVisibility"
                      :callback="sendMail"
                      :default-title="`${$t('navigation.global_violations_report')}`"
                      @close="closeSendMail"
                      @visibility="sendMailVisibility = $event"></SendMail>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      :items="globalViolations"
                      disable-pagination
                      hide-default-footer
                      @dblclick:row="showRowDetails"
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header, on }">
                <SortableHeader :header-item="header"
                                v-on="on"
                                :key="i"></SortableHeader>
            </template>

            <template v-slot:item.question="{ item }">
                <span class="clickable"
                      @click="showDetails(item)">{{ item.question }}</span>
            </template>
        </v-data-table>
        <ViolationPerQuestion :dialog="detailsVisibility"
                              :question="chosenQuestion"
                              :start-date="startDate"
                              :end-date="endDate"
                              v-if="detailsVisibility"
                              @visibility="detailsVisibility = $event"
                              @close="closeDetails"></ViolationPerQuestion>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ICONS } from '@/constants/icons';
import ViolationPerQuestion from '@/components/reports/violationPerQuestion/ViolationPerQuestion';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import GetFile from '@/components/widgets/buttons/GetFile';
import SendMail from '@/components/widgets/dialogs/SendMail';

export default {
    name: 'GlobalViolationsTable',
    components: { ViolationPerQuestion, SortableHeader, GetFile, SendMail },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        pdfLoader: {
            type: Boolean,
            'default': false
        },
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        },
        filters: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        detailsVisibility: false,
        sendMailVisibility: false,
        chosenQuestion: '',
        ICONS
    }),
    computed: {
        headers () {
            return [
                {
                    text: this.$tc('labels.question', 1),
                    value: 'question',
                    sortable: true
                },
                {
                    text: this.$t('labels.amount_number'),
                    value: 'occurence_count',
                    sortable: true
                }
            ];
        },
        ...mapState('reports', [
            'globalViolations'
        ]),
        ...mapState('generalConfigurations', [
            'host'
        ]),
        ...mapFields('sendMail', [
            'mailData.agency',
            'mailData.kindOfReport',
            'mailData.report'
        ])
    },
    methods: {
        showDetails (item) {
            const params = {
                question: item.question,
                start_time: this.startDate,
                end_time: this.endDate
            };

            this.$store.dispatch('reports/getViolationsPerQuestion', params)
                .then(() => {
                    this.chosenQuestion = item.question;
                    this.detailsVisibility = true;
                });
        },
        showRowDetails (event, { item }) {
            this.showDetails(item);
        },
        closeDetails () {
            this.detailsVisibility = false;
            this.chosenQuestion = '';
        },
        emitGetFile (type) {
            this.$emit('get-file', type);
        },
        showSendMail () {
            this.sendMailVisibility = true;
        },
        sendMail () {
            this.agency = this.host;
            this.kindOfReport = 'irregularities';
            this.report = this.filters;

            return this.$store.dispatch('sendMail/sendReport');
        },
        closeSendMail () {
            this.sendMailVisibility = false;
            this.$store.commit('sendMail/RESET_MAIL_DATA');
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-data-table {
            & table {
                & .v-data-table-header__icon {
                    display: none;
                }
            }
        }
    }
</style>
