<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  class="mb-8">
            <GetFile :loader="pdfLoader"
                     class="mr-3"
                     @get-file="emitGetFile('pdf')">
            </GetFile>
            <GetFile :title="$t('buttons.export_xls')"
                     :loader="xlsLoader"
                     @get-file="emitGetFile('xls')">
                <template slot="icon">
                    <v-icon class="mr-1"
                            size="20">{{ ICONS.EXPORT }}</v-icon>
                </template>
            </GetFile>
            <v-btn color="secondary"
                   small
                   @click="showSendMail"
                   class="ml-3">{{ $t('buttons.send_mail') }}</v-btn>
            <SendMail :dialog="sendMailVisibility"
                      :callback="sendMail"
                      :default-title="`${$t('labels.question_report')}`"
                      @close="closeSendMail"
                      @visibility="sendMailVisibility = $event"></SendMail>
        </v-layout>

        <v-data-table :headers="headers"
                      :loading="loader"
                      :items="violationsPerQuestion"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header, on }">
                <SortableHeader :header-item="header"
                                v-on="on"
                                :key="i"></SortableHeader>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import GetFile from '@/components/widgets/buttons/GetFile';
import SendMail from '@/components/widgets/dialogs/SendMail';

export default {
    name: 'ViolationPerQuestionTable',
    components: { SortableHeader, GetFile, SendMail },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        pdfLoader: {
            type: Boolean,
            'default': false
        },
        filters: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        ICONS,
        sendMailVisibility: false
    }),
    computed: {
        ...mapState('reports', [
            'violationsPerQuestion'
        ]),
        ...mapState('generalConfigurations', [
            'host'
        ]),
        ...mapFields('sendMail', [
            'mailData.agency',
            'mailData.kindOfReport',
            'mailData.report'
        ]),
        headers () {
            return [
                {
                    text: this.$t('labels.customer'),
                    value: 'customer',
                    sortable: true
                },
                {
                    text: this.$t('labels.site'),
                    value: 'place',
                    sortable: true
                },
                {
                    text: this.$t('labels.location'),
                    value: 'point',
                    sortable: true
                },
                {
                    text: this.$t('labels.amount_number'),
                    value: 'question_count',
                    sortable: true
                }
            ];
        }
    },
    methods: {
        emitGetFile (type) {
            this.$emit('get-file', type);
        },
        showSendMail () {
            this.sendMailVisibility = true;
        },
        sendMail () {
            this.agency = this.host;
            this.kindOfReport = 'customer_irregularities';
            this.report = this.filters;

            return this.$store.dispatch('sendMail/sendReport');
        },
        closeSendMail () {
            this.sendMailVisibility = false;
            this.$store.commit('sendMail/RESET_MAIL_DATA');
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-data-table-header__icon {
            display: none;
        }
    }
</style>
