export default {
    data: () => ({
        xlsLoader: false,
        pdfLoader: false
    }),
    methods: {
        getXLS (params) {
            this.xlsLoader = true;

            return this.$store.dispatch('commonActions/getReportXLS', params)
                .finally(() => {
                    this.xlsLoader = false;
                });
        },
        getPDF (params) {
            this.pdfLoader = true;

            return this.$store.dispatch('commonActions/getReportPDF', params)
                .finally(() => {
                    this.pdfLoader = false;
                });
        },
        getFile (event, params) {
            switch (event) {
            case 'xls': {
                this.getXLS(params);
                break;
            }
            case 'pdf': {
                this.getPDF(params);
                break;
            }
            }
        }
    }
};
